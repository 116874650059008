import { For, Show } from "solid-js"
import { Product } from "../products/product-types";
import { StyledText,
    StyledMixingRow,
    StyledMixingStatusInfo,
    StyledMixingNotes,
    StyledMixingName
} from './mixing-table.styles';
import { MixingCode, MixingTableListProps } from "../../containers/mixing-table/mixing-table-types";

const getMixingStatusDescription = (code: MixingCode) => {
    const mixingCodes = {
        B: 'Får blandas',
        BA: 'OK med viss antagonism',
        BR: 'OK men resistensrisk',
        BO: 'OK under omrörning',
        '-': 'Ej blandbar',
        'B*': 'Kan blandas men passar ej',
        U: 'Blandbart utan vätmedel',
        IG: 'Blandningen ej aktuell',
    };

    return mixingCodes[code];
};

export const MixingTableList = (props: MixingTableListProps) => {
    const mixings = () => {
        if (props.product2) {
            return props.product.mixings.filter((product: Product) => product.slug === props.product2);
        } else if (props.statusFilter?.length) {
            return props.product.mixings.filter((product: Product) => {
                if (props.statusFilter?.includes(product.status)) {
                    return true;
                } else if (product.status === '' && props.statusFilter?.includes('I')) {
                    return true;
                }
                return false;
            });
        }
        return props.product.mixings;
    };

    return (
        <Show when={mixings().length} fallback={<StyledText center={true}>Inga blandningar för valda preparat</StyledText>}>
            <StyledText>Valt preparat: {props.product.name}</StyledText>
            <For each={mixings()}>
                {(mixing: Product) => (
                    <StyledMixingRow>
                        <StyledMixingStatusInfo status={mixing.status}>
                            <StyledMixingName>{mixing.name}</StyledMixingName>
                            <div>
                                {getMixingStatusDescription(mixing.status)}
                                <Show when={mixing.days}>
                                    <span>
                                        {' '}
                                        (minst {mixing.days} {mixing.days === 1 ? 'dag' : 'dagar'} mellan behandlingarna)
                                    </span>
                                </Show>
                            </div>
                        </StyledMixingStatusInfo>
                        {mixing.notes ? <StyledMixingNotes>{mixing.notes}</StyledMixingNotes> : null}
                    </StyledMixingRow>
                )}
            </For>
        </Show>
    );
};
